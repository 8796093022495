<template>
  <div class="writing">
    <!-- 搜索 -->
    <div class="search_box">
      <div class="searchBox_input">
        <el-input
          v-model="input"
          @keyup.enter.native="searchList"
          placeholder="如何正确使用溯源"
        ></el-input>
      </div>
      <div class="searchBox_btn" @click="searchList">
        <img :src="require('assets/images/idiom.png')" alt="" />
        <img :src="require('assets/images/included_search.png')" alt="" />
      </div>
    </div>

    <!-- 分类 -->
    <div class="classify_box">
      <div class="classify_list" ref="classify">
        <div class="classify_list_item">分类</div>
        <div class="classify_list_content">
          <div
            class="classify_list_content_item"
            :class="index == classifyIndex ? 'blue' : ''"
            v-for="(item, index) in classify"
            :key="index"
            @click="classifyClick(item, index)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>

    <!-- 排序 -->
    <div class="sort_box">
      <div class="sort_text">排序</div>

      <div class="sort_list">
        <div
          class="sort_item"
          v-for="(item, index) in sortArr"
          :key="index"
          @click="sortClick(item)"
        >
          <div class="item_text">
            {{ item.title }}
          </div>
          <i class="item_icon" :class="item.sort == 'desc' ? 'up' : 'down'"></i>
        </div>
      </div>
    </div>

    <!-- 视频 -->
    <div class="video_box">
      <div
        class="video_item"
        @click="videoClikc(item)"
        v-for="item in videoList"
        :key="item.id"
      >
        <div class="video_img">
          <img :src="url + item.imgUrl" alt="" />
          <img
            class="img"
            :src="require('assets/images/writing/shadow.png')"
            alt=""
          />
          <!-- <div class="duration">9:30</div> -->
        </div>
        <div class="video_title">{{ item.title }}</div>
        <div class="video_related">
          <div class="heat">
            <i class="heat_icon" />
            {{ item.hot }}
          </div>
          <div class="score">
            <i class="score_icon" />
            {{ item.star.toFixed(1) }}
          </div>
          <div class="review">
            <i class="score_icon" />
            {{ item.commentNum }}
          </div>
        </div>
      </div>

      <!-- flex布局占位盒子 -->
      <div class="advice" v-for="item1 in 5" :key="item1 + '2+0'"></div>
    </div>
  </div>
</template>

<script>
import { getListById, getClassList } from "api/videoWriting";
export default {
  name: "writingIndex",
  data() {
    return {
      url: this.$store.state.onlineBasePath,
      // 搜索关键字
      input: "",
      // 分类
      classify: [
        {
          title: "全部",
          id: "",
        },
      ],
      // 分类点击index
      classifyIndex: 0,
      // 排序
      sortArr: [
        {
          title: "时间",
          sort: "desc",
          id: 1,
        },
        {
          title: "热度",
          sort: "desc",
          id: 2,
        },
        {
          title: "评分",
          sort: "desc",
          id: 3,
        },
        {
          title: "评论",
          sort: "desc",
          id: 4,
        },
      ],
      // 选择的分类id
      parentId: "",
      // 排序类型
      sortType: "",
      // 排序升降
      sortVal: "desc",
      // 视频列表
      videoList: [],
      // 视频总数
      total: 0,
    };
  },

  methods: {
    // 获取列表
    async getVideoList() {
      const params = {
        page: 1,
        limit: 10,
        parentId: this.parentId,
        title: this.input,
        timeSort: this.sortType == 1 ? (this.sortVal == "desc" ? 1 : 2) : "",
        hotSort: this.sortType == 2 ? (this.sortVal == "desc" ? 1 : 2) : "",
        starSort: this.sortType == 3 ? (this.sortVal == "desc" ? 1 : 2) : "",
        commentSort: this.sortType == 4 ? (this.sortVal == "desc" ? 1 : 2) : "",
      };

      const res = await getListById(params);
      this.videoList = res.data.data;
      this.total = res.data.total;
    },

    // 获取分类列表
    async getClassifyList() {
      const res = await getClassList();
      // console.log(res);
      this.classify = [...this.classify, ...res.data];
    },

    // 搜索事件
    searchList() {
      this.getVideoList();
    },
    // 分类item点击
    classifyClick(item, index) {
      this.parentId = item.id;
      this.classifyIndex = index;
      this.getVideoList();
    },
    // 排序点击
    sortClick(item) {
      if (item.sort == "desc") {
        item.sort = "asc";
      } else {
        item.sort = "desc";
      }
      this.sortType = item.id;
      this.sortVal = item.sort;
      this.getVideoList();
    },
    // 视频 item 点击
    videoClikc(val) {
      this.$router.push({
        name: "videoWritingInfo",
        params: {
          id: val.id,
        },
      });
    },
  },

  created() {
    this.getVideoList();
    this.getClassifyList();
  },
};
</script>

<style lang="scss" scoped>
.writing {
  background: #ffffff;
  padding: 30px 40px;
  // 搜索
  .search_box {
    background: #ffffff;
    border: 1px solid #ff6900;
    width: 750px;
    margin: 0 auto 20px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .searchBox_input {
      margin-left: 10px;
      height: 26px;
      width: 80%;
      ::v-deep .el-input {
        height: 26px;
        .el-input__inner {
          height: 26px;
          line-height: 26px;
          border: none;
        }
      }
    }

    .searchBox_btn {
      // width: 40px;
      height: 40px;
      padding: 0 10px;
      background: #ff6900;
      // @include backgroundGroup("~assets/images/idiom.png");
      @include flex-center();
      border-radius: 0 5px 5px 0;
      cursor: pointer;
      > img:first-child {
        width: 52px;
        height: 22px;
        margin: 0 10px;
      }
    }
  }

  // 分类
  .classify_box {
    font-size: 14px;
    .classify_list {
      background-color: #ffbf6914;
      padding: 15px 20px;
      display: flex;
      align-items: center;
      .classify_list_item {
        margin-right: 25px;
      }

      .classify_list_content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .classify_list_content_item {
          cursor: pointer;
          margin-right: 35px;
          color: #999999;
        }

        .blue {
          color: #ff6900;
        }
      }
    }
  }

  // 排序
  .sort_box {
    margin-top: 30px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 14px;

    .sort_text {
      margin-right: 25px;
    }

    .sort_list {
      display: flex;
      align-items: center;

      .sort_item {
        margin-right: 25px;
        display: flex;
        margin-right: 20px;
        cursor: pointer;
        .item_text {
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-black;
        }

        .item_icon {
          width: 9px;
          height: 13px;
          margin-left: 6px;
        }

        .up {
          @include backgroundGroup("~assets/images/up.png");
        }

        .down {
          @include backgroundGroup("~assets/images/down.png");
        }
      }
    }
  }

  // 视频
  .video_box {
    margin-top: 30px;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .video_item {
      cursor: pointer;
      margin-bottom: 20px;
      width: 264px;
      border: 1px solid #f3f3f3;
      box-shadow: 0px 4px 7px 0px rgba(206, 206, 206, 0.25);

      .video_img {
        width: 100%;
        height: 148px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }

        .img {
          height: auto;
          transform: translateY(-59px);
        }

        .duration {
          position: absolute;
          bottom: 10px;
          right: 10px;
          color: #fff;
          opacity: 0.8;
        }
      }

      .video_title {
        padding: 10px 20px;
      }

      .video_related {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        color: #666666;
        .heat {
          display: flex;
          align-items: center;
          .heat_icon {
            width: 14px;
            height: 14px;
            margin-right: 10px;
            background: url("~assets/images/writing/heat.png") no-repeat;
            background-size: 100% 100%;
          }
        }

        .score {
          display: flex;
          align-items: center;
          .score_icon {
            width: 14px;
            height: 14px;
            margin-right: 10px;
            background: url("~assets/images/writing/score.png") no-repeat;
            background-size: 100% 100%;
          }
        }

        .review {
          display: flex;
          align-items: center;
          .score_icon {
            width: 14px;
            height: 14px;
            margin-right: 10px;
            background: url("~assets/images/writing/review.png") no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }

    .advice {
      width: 264px;
      height: 0;
    }
  }
}
</style>
